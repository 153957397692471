import image1 from '../../assets/realPics/5.jpeg'
import image2 from '../../assets/realPics/10.jpeg'
import image3 from '../../assets/realPics/12.jpeg'
import image4 from '../../assets/realPics/6.jpeg'
import image5 from '../../assets/realPics/9.jpeg'
import image6 from '../../assets/realPics/18.jpeg'
import image7 from '../../assets/realPics/3.jpeg'
import image8 from '../../assets/realPics/13.jpeg'
import image9 from '../../assets/realPics/16.jpeg'
import image10 from '../../assets/realPics/10.jpeg'
import image11 from '../../assets/realPics/11.jpeg'
import image12 from '../../assets/realPics/12.jpeg'

const images = {
    cafe: [
image1,image2,image3
],

bday: [
image7,image8,image9
]
,
kitty: [
image11,image12,image10
]
,
eventParty: [
image4,image5,image6
]


}
export default images;