import React from 'react'
import './HomeCarousel.css'
const HomeCarousel = () => {
  return (
    <div className='Carousel'>
      
    </div>
  )
}

export default HomeCarousel
